<script setup>
import { ref, onBeforeUnmount, onMounted, } from 'vue';


const rootEl = ref(null);

const props = defineProps({
    blockId: {
        type: String,
        default: '',
    },
    msId: {
        type: String,
        default: '',
    },
    websiteId: {
        type: String,
        default: '',
    },
    hostname: {
        type: String,
        default: '',
    },
});

const removeMSDynamicsJS = () => {
    const msDynamicsFormLoader = document.querySelector('#ms_dynamics_form_loader');
    if (msDynamicsFormLoader) {
        document.head.removeChild(msDynamicsFormLoader);
    }
    const msDynamicsFormTracking = document.querySelector('#ms_dynamics_form_tracking');
    if (msDynamicsFormTracking) {
        document.head.removeChild(msDynamicsFormTracking);
    }
    const forms = rootEl.value.querySelectorAll('[data-form-block-id] form, [data-form-block-id] .formLoader');
    if (forms) {
        for (let i = 0, il = forms.length; i < il; ++i) {
            const form = forms[i];
            if (form?.parentNode) {
                form.parentNode.removeChild(form);
            }
        }
    }

    const iframes = document.querySelectorAll('iframe[src*="dynamics.com"]');
    if (iframes) {
        for (let i = 0, il = iframes.length; i < il; ++i) {
            const iframe = iframes[i];
            if (iframe) {
                iframe.remove();
            }
        }
    }

    if (window?.MsCrmMkt?.Tracking) {
        window.MsCrmMkt.Tracking = undefined;
    }
    if (window?.MsCrmMkt) {
        window.MsCrmMkt = undefined;
    }
};

const addMSDynamicsJS = () => {
    let msDynamicsFormLoader = document.querySelector('#ms_dynamics_form_loader');
    let msDynamicsFormTracking = document.querySelector('#ms_dynamics_form_tracking');

    if (!msDynamicsFormLoader && !msDynamicsFormTracking) {
        // console.log('MsDynamics will be added');
        msDynamicsFormTracking = document.createElement('script');
        msDynamicsFormLoader = document.createElement('script');
        msDynamicsFormLoader.setAttribute('id', 'ms_dynamics_form_loader');
        msDynamicsFormLoader.setAttribute(
            'src',
            'https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js',
        );
        document.head.appendChild(msDynamicsFormLoader);
        msDynamicsFormLoader.addEventListener('load', () => {
            msDynamicsFormTracking.setAttribute('id', 'ms_dynamics_form_tracking');
            msDynamicsFormTracking.setAttribute(
                'src',
                'https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js',
            );
            document.head.appendChild(msDynamicsFormTracking);
        });
    } else {
        // console.log('MsDynamics was previously added');
    }
};
let timeout = 0;
onMounted(() => {
    // console.log('dynamicsonMounted');
    window.clearTimeout(timeout);
    timeout = window.setTimeout(() => {
        // console.log('timeout, maybe add ms dynamics');
        // if (window.location.hostname !== 'localhost') {
        addMSDynamicsJS();
        // }
    }, 4000);
});

onBeforeUnmount(() => {
    removeMSDynamicsJS();
});

/**
 * For styles, look at main.css
 */
</script>

<template>
    <div ref="rootEl">
        <div :data-form-block-id="blockId" />
        <div :id="msId" />
        <!-- Note: should only be added once, moved to App.vue statically -->
        <!-- <div
            class="d365-mkt-config"
            style="display:none"
            :data-website-id="websiteId"
            :data-hostname="hostname"
        /> -->
    </div>
</template>
