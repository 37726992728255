<script setup>
import {
    computed, watch, ref, nextTick, onMounted,
} from 'vue';
import { useRoute } from 'vue-router';
import { gsap } from 'gsap';
import { getClient } from '@/core/js/GraphqlClient.js';
import { useStore } from '@/store/Store.js';
import 'focus-visible';


import useUi from '@/components/ui/Ui.js';
import useSeo from '@/components/seo/Seo.js';
import FindBio from '@/components/find-bio/FindBio.vue';
import Navigation from '@/components/navigation/Navigation.vue';
import Footer from '@/components/footer/Footer.vue';
import ScrollTop from '@/components/scrolltop/ScrollTop.vue';
import PopupNewsletter from '@/components/popupnewsletter/PopupNewsletter.vue';
import CookieConsent from '@/components/cookie-consent/CookieConsent.vue';
import Search from '@/components/search/Search.vue';
import { useLenis } from '@/components/scroll/Lenis.js';

const lenis = useLenis();
const store = useStore();
const currentRoute = useRoute();
const nav = ref(null);
const rootEl = ref(null);
getClient();
useSeo();
useUi();

const checkHashScroll = () => {
    window.setTimeout(() => {
        if (window.location.hash) {
            const elToScrollTo = document.querySelector(window.location.hash);
            if (elToScrollTo) {
                lenis.value.scrollTo(elToScrollTo, {
                    duration: 1.5,
                });
            }
        }
    }, 500);
};

gsap.config({
    force3D: true,
});

const enterPage = (el, done) => {
    if (typeof currentRoute?.params?.noscroll === 'undefined') {
        lenis.value.scrollTo(0, { immediate: true });
    }

    done();
};

let defaultCssClass = 'portrait';
if (store.md.value) {
    defaultCssClass = 'tablet';
} else if (store.xl.value) {
    defaultCssClass = 'landscape';
}

document.documentElement.classList.add(defaultCssClass);

const orientation = computed(() => {
    if (store.md.value) {
        return 'tablet';
    }
    if (store.xl.value) {
        return 'landscape';
    }
    return 'portrait';
});

watch(orientation, (newVal, oldVal) => {
    if (!oldVal) {
        document.documentElement.classList.remove(defaultCssClass);
    } else {
        document.documentElement.classList.remove(oldVal);
    }
    document.documentElement.classList.add(newVal);
});

const isFooterRendered = ref(false);
const isPopupRendered = ref(false);
const isPageRendered = ref(false);

const declareFooterRendered = () => {
    isFooterRendered.value = true;
};
const declarePopupRendered = () => {
    isPopupRendered.value = true;
};

let loadTimeout = 0;
const enterLoading = () => {
    // Don't show loading in the first 250ms in case we have really good connection
    window.clearTimeout(loadTimeout);
    store.isLoading.value = true;
    loadTimeout = window.setTimeout(() => {
        document.documentElement.classList.add('wait');
    }, 250);
};

const leaveLoading = () => {
    window.clearTimeout(loadTimeout);
    isPageRendered.value = true;
    store.isLoading.value = false;
    document.documentElement.classList.remove('wait');
    nextTick(() => {
        checkHashScroll();
    });
};

onMounted(() => {
    nextTick(() => {
        checkHashScroll();
    });
});

</script>

<template>
    <div id="AppComponent" ref="rootEl">
        <div
            class="d365-mkt-config"
            data-website-id="JdmbmOhOsFka5jYCKH_YrebPwjbOILOl7hrrKDXKIuQ"
            data-hostname="2c24ef126db343e1811a9fd1ae798f33.svc.dynamics.com"
            style="display:none;"
        />
        <Suspense>
            <Navigation ref="nav" />
        </Suspense>
        <Search />
        <div class="Page w-full">
            <div
                class="App__page relative z-10 min-h-[200vh]"
                :class="['bg-bright-'+store.headerColor.mainColor]"
            >
                <router-view v-slot="{ Component, route }">
                    <transition
                        name="custom-classes"
                        enter-active-class="transition duration-1000"
                        leave-active-class="transition absolute top-0 z-1 duration-1000"
                        enter-from-class="opacity-0"
                        enter-to-class="opacity-100"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                        v-on:enter="enterPage"
                    >
                        <Suspense
                            v-on:pending="enterLoading"
                            v-on:resolve="leaveLoading"
                        >
                            <component
                                :is="Component"
                                :key="route.path"
                                :class="['bg-bright-'+store.headerColor.mainColor]"
                            />
                        </Suspense>
                    </transition>
                </router-view>
            </div>
            <CookieConsent v-if="!isBot" />
            <Suspense>
                <Footer v-on:footer-rendered="declareFooterRendered" />
            </Suspense>
            <FindBio v-if="isFooterRendered && ui.find_bio && currentRoute.meta.name != 'discount'" />
            <ScrollTop v-if="isFooterRendered && (landscape || tablet)" />
            <Suspense>
                <PopupNewsletter v-if="!isBot" v-on:popup-rendered="declarePopupRendered" />
            </Suspense>
        </div>

        <!-- <template #fallback>
            <Header
                :data="{suptitle:'', subtitle:'', title:'', subsubtitle:'', supsubtitle:''}"
                :color="'light-'+store.headerColor.mainColor"
                :title-color="'dark-'+store.headerColor.mainColor"
                type-pancarte="sentier"
            />
        </template> -->

        <div v-if="dev" class="fixed bottom-0 right-0 z-50 flex w-12 items-center justify-center bg-light-gray text-xs text-black">
            <div class="block sm:hidden">
                xs
            </div>
            <div class="hidden sm:block md:hidden">
                sm
            </div>
            <div class="hidden md:block lg:hidden">
                md
            </div>
            <div class="hidden lg:block xl:hidden">
                lg
            </div>
            <div class="hidden xl:block 2xl:hidden">
                xl
            </div>
            <div class="hidden 2xl:block 3xl:hidden">
                2xl
            </div>
            <div class="hidden 3xl:block">
                3xl
            </div>
        </div>
    </div>
</template>
